import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import SEO from '../components/SEO'
import Layout from "../components/Layout";
import { parseContent } from "../helpers/city-pages";

function AreasServicedPage() {
  return (
    <StaticQuery
      query={graphql`
        query getServiceAreas {
          allServiceAreaContent: allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "service-area" } } }
          ) {
            edges {
              node {
                id
                frontmatter {
                  slug
                  featuredcity
                  title
                }
              }
            }
          }
          serviceContent: allMarkdownRemark(
            filter: {
              frontmatter: { templateKey: { eq: "service-area-content" } }
            }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  slug
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        let citiesList = [
          ...new Map(
            data.allServiceAreaContent.edges.map((item) => [item.node.id, item])
          ).values(),
        ];
        let servicesList = [
          ...new Map(
            data.serviceContent.edges.map((item) => [item.node.id, item])
          ).values(),
        ];

        return (
          <Layout>
            <SEO
              title={"Service Area | Bay Area Siding Company"}
              description={"Bay Area Siding Company Service Area for Providing Exterior House Siding Replacement & Installation Services."}
            />
            <section className="w-full px-4 md:px-6 py-6 container mx-auto">
                <h1 className="md:text-4xl font-semibold leading-10 text-3xl">Service Area</h1>
                <p className="text-gray-700 leading-6">Bay Area Siding Company is proud to provide exceptional siding replacement services to the following areas. If your city is not listed below, please contact us and we will be happy to assist.</p>
              <div className="py-4 mt-6">
                <ul className="mt-2 leading-relaxed p-0 list-none flex flex-wrap">
                  {data.allServiceAreaContent &&
                    data.allServiceAreaContent.edges &&
                    citiesList.map((city) => {
                      const { title, slug } = city.node.frontmatter;
                      return (
                        <li
                          key={city.node.id}
                          className="pb-4 text-lg font-semibold font-display leading-6 text-gray-800 flex-shrink-0 w-full sm:w-1/2 md:w-1/3"
                        >
                          <p className="block">
                            {title}, CA
                          </p>
                          <div className="py-1">
                            {servicesList.map((service, s) => { 
                              return (
                                <Link
                                  key={s}
                                  to={`/${slug}-${service.node.frontmatter.slug}/`}
                                  className="text-base font-normal block"
                                  title={parseContent(
                                    service.node.frontmatter.title,
                                    title + ", CA"
                                  )}
                                >
                                  {parseContent(
                                    service.node.frontmatter.title,
                                    title + ", CA"
                                  )}
                                </Link>
                              );
                            })}
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </section>
          </Layout>
        );
      }}
    />
  );
}

export default AreasServicedPage;
